import {
  PRIZE_DRAW_ACTIVE,
  PRIZE_DRAW_CLOSED,
  PRIZE_DRAW_BROKEN,
  PRIZE_DRAW_DRAWN,
  PRIZE_DRAW_SOLD_OUT,
  PRIZE_DRAW_NO_RAFFLE
} from '../../constants';
import { DateTime } from 'luxon';

const PrizeHeadline = ({ status, title, description, shortDescription, drawDate, prizes }) => {
  let _title = title;
  let _description = description;
  let _subtitle = false;
  let _drawDate = DateTime.fromISO(drawDate).toLocaleString(DateTime.DATE_FULL);
  
  if (status === PRIZE_DRAW_CLOSED) {
    _title = "The Audi Foundation prize draw is now closed";
    _description = `<p>
        Thank you for your interest in the Audi Foundation prize draw.
        Unfortunately entries are now closed.
        The draw will take place on ${_drawDate} and the winners
        will be contacted directly by Audi Australia.
        </p><p>
        You can still support the community projects undertaken by our partners
        by donating to the Audi Foundation directly.
    </p>`;
  }
  
  if (status === PRIZE_DRAW_SOLD_OUT) {
    _title = "The Audi Foundation prize draw is now sold out";
    _description = `<p>
        Thank you for your interest in the Audi Foundation prize draw.
        Unfortunately tickets are now sold out.
        The draw will take place on ${_drawDate} and the winners
        will be contacted directly by Audi Australia.
        </p><p>
        You can still support the community projects undertaken by our partners
        by donating to the Audi Foundation directly.
    </p>`;
  }
  
  if (status === PRIZE_DRAW_BROKEN) {
    _title = "Thank you for helping us make a difference.";
    _description = `<p>
      Due to the overwhelming response, we are currently experiencing some delays in processing the competition entries.
      </p><p>
      Please try again later to support a good cause and earn yourself the chance to win.
      </p><p>
      The Audi Foundation Team
    </p>`;
  }
  
  if (status === PRIZE_DRAW_DRAWN) {
    var textLuckyWinner = "the lucky winner is listed below";
    if (prizes && prizes.length > 1) {
      textLuckyWinner = "the lucky winners are listed below";
    }
    _title = "Audi Foundation prize draw winner";
    _description = `
      <p>The prize draw took place on ${_drawDate} and ${textLuckyWinner}.
      </p><p>Thank you to everyone who entered the Audi Foundation prize draw and provided vital funding for community projects across Australia.
      </p><p>You can still support the community projects undertaken by our charity partners by donating to the Audi Foundation directly.</p>
    `
  }
  
  if (status === PRIZE_DRAW_NO_RAFFLE) {
    _subtitle = shortDescription;
  }
  
  return (
    <div className="page-intro mt-0 mb-4 prize-headline cms-section-list">
      <h1>{_title}</h1>
      {_subtitle && <h3>{_subtitle}</h3>}
      <div className="prize-headline__description" dangerouslySetInnerHTML={{ __html: _description }} />
    </div>
  );
}

export default PrizeHeadline;
