import React from 'react';
import Button from '../../audi-ui-components/Button';
import Loading from '../../components/Loading';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import AddressField from '../../components/AddressField';
import { Link } from 'react-router-dom';
import API from '../../functions/api';
import { formatMobile, formatDate } from '../../functions/stringHelpers';
import { loadStripe, formatCreditCard, formatCCExpire } from '../../functions/payment';
import { validate, defaultSchema } from '../../functions/validation';
import { gtmPush } from '../../functions/gtm';
import _difference from 'lodash/difference';

import {
  PATH_FAQ,
  FIELD_CHECKBOX,
  LINK_PRIVACY
} from '../../constants';

class PrizeDrawForm extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      expandAddressOnError: false,
      error: false
    };
  }
  
  componentDidMount() {
    loadStripe(process.env.RAZZLE_STRIPE_KEY_DRAW);
  }
  
  validateForm = (values) => {
    let schema = {
      firstName: defaultSchema.firstName,
      lastName: defaultSchema.lastName,
      email: defaultSchema.email,
      phoneNumber: defaultSchema.phoneNumber,
      birthDate: {
        dob18: true
      },
      ticketCount: {
        presence: {message: "This field is required", allowEmpty: false},
        ticketLimit: {maxPerPerson: this.props.ticketPerPerson, maxAvailable: this.props.ticketOverall}
      },
      addressLine1: {
        presence: {message: "This field is required", allowEmpty: false},
        length: {maximum: 100, tooLong: "Too long (max 100 characters)"}
      },
      addressLine2: {
        length: {maximum: 100, tooLong: "Too long (max 100 characters)"}
      },
      addressCity: {
        presence: {message: "This field is required", allowEmpty: false},
        length: {maximum: 50, tooLong: "Too long (max 50 characters)"}
      },
      addressPostcode: {
        presence: {message: "This field is required", allowEmpty: false},
        length: {maximum: 10, tooLong: "Too long (max 10 characters)"}
      },
      addressState: {
        presence: {message: "This field is required", allowEmpty: false},
        stateHasPermit: {validStates: this.props.validStates},
        length: {maximum: 10, tooLong: "Too long (max 10 characters)"}
      }
    };
    if (this.state.step === 2) {
      schema.cardNumber = {
        stripeCardNumber: true,
        presence: {message: "This field is required", allowEmpty: false}
      };
      schema.cardExpiry = {
        stripeCardExpiry: true,
        presence: {message: "This field is required", allowEmpty: false}
      };
      schema.cardCVC = {
        stripeCVC: true,
        presence: {message: "This field is required", allowEmpty: false}
      };
      schema.agreedTerms = {
        presence: {message: "This field is required", allowEmpty: false}
      }
    }
    return validate(values, schema, {format: "firstError", fullMessages: false});
  }
  
  submitForm = (values, formProps) => {
    // console.log("submitForm", this.state.step);
    const { ticketPrice } = this.props;
    // go to step 2
    if (this.state.step === 1) {
      formProps.setSubmitting(false);
      formProps.resetForm({values});
      this.setState({step: 2});
      let total = parseInt(values.ticketCount) * ticketPrice;
      gtmPush("audiFoundation", "enterPaymentDetails", `payment_amount = ${total}`, {totalAmount: total, ...values});
      // thneed.trigger('enterPaymentDetails', 'audiFoundation', {totalAmount, ...data}, true);
      return;
    }
    const { drawId } = this.props;
    // Stripe
    let Stripe = null;
    if (typeof window !== 'undefined' && window.Stripe) {
      Stripe = window.Stripe;
    } else {
      this.setState({error: "Payment gateway not loaded"});
      formProps.setSubmitting(false);
      return;
    }
    const cardDetails = {
      number: values.cardNumber.replace(/ /g, ''),
      cvc: values.cardCVC,
      exp_month: values.cardExpiry.substr(0,2),
      exp_year: values.cardExpiry.substr(3),
    }
    Stripe.card.createToken(cardDetails, (status, response) => {
      if (response.error) {
        this.setState({error: response.error.message});
        formProps.setSubmitting(false);
        return;
      } else {
        // Stripe success, submit to backend
        let payload = Object.assign({}, values, {
            chargeToken: response.id,
            optout: !values.optin,
            birthDate: values.birthDate.split("/").reverse().join("-")
        });
        console.log(payload);
        API.post(
          `/draw/${drawId}/submit`,
          payload
        ).then((response) => {
            // console.log(response);
            gtmPush("audiFoundation", "confirmPayment", "prize_draw_submitted", {totalAmount: parseInt(values.ticketCount) * ticketPrice, ...values});
            this.props.onSubmit(response);
        }).catch((error) => {
            // console.error(error);
            let msg = "An error has occured.";
            if (error.response && error.response.data) {
              if (error.response.data.message) {
                msg = error.response.data.message;
              }
              if (error.response.data.modelState) {
                formProps.setErrors(error.response.data.modelState);
              }
            }
            this.setState({error: msg});
            formProps.setSubmitting(false);
        });
      }
    });
  }
  
  render() {
    const { ticketPrice, ticketPerPerson, ticketOverall, drawDate, validStates } = this.props;
    const invalidStates = _difference(['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'], validStates);
    return (
      <div className="form-section">
        <h4 className="aui-headline-4 mb-2 text-center">Enter your details for a chance to win</h4>
        {ticketPrice && <p className="text-center mb-2">
          Each entry costs {ticketPrice % 1 !== 0 ? `$${ticketPrice.toFixed(2)}` : `$${ticketPrice}`} and proceeds go to our charity partners, 
          helping them make a difference in education, youth mental health, medical technology and research.
        </p>}
        <p className="text-center mb-3">
          {invalidStates && invalidStates.length > 0 && <i>
            Residents of {invalidStates.join(", ")} are not eligible to enter.<br />
          </i>}
          <i>See <Link to={PATH_FAQ} className="aui-textlink">FAQs</Link> for more information.</i>
        </p>
        
        <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={{}}>
          {(formProps) => (
            <Form>
              <div className="row">
                <FormField name="firstName" label="First name" wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
                <FormField name="lastName" label="Last name" wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
              </div>
              <div className="row">
                <FormField name="email" label="Email address" trim wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
                <FormField name="phoneNumber" label="Mobile number" formatValue={formatMobile} wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
              </div>
              <div className="row">
                <FormField name="birthDate" label="Date of birth (DD/MM/YYYY)" formatValue={formatDate} wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
                <FormField name="ticketCount" label="How many tickets would you like?" type="number" wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
              </div>
              <AddressField {...formProps} />
              {this.state.step === 1 && <p className="text-center">
                <Button variant="primary" className="red" type="submit">Enter payment details</Button>
              </p>}
              
              {this.state.step === 2 && <>
                <h4 className="aui-headline-4 mb-2 text-center">Enter your payment details</h4>
                <FormField name="cardNumber" label="Card number" formatValue={formatCreditCard} formProps={formProps} />
                <div className="row">
                  <FormField name="cardExpiry" label="Expiry (MM/YY)" formatValue={formatCCExpire} wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
                  <FormField name="cardCVC" label="CVC" wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
                </div>
                
                <FormField name="agreedTerms" fieldType={FIELD_CHECKBOX} label={<>I agree to the <a target="_blank" rel="noreferrer" className="aui-textlink" href={this.props.termsPdf}>terms and conditions</a></>} formProps={formProps} />
                <FormField name="optin" fieldType={FIELD_CHECKBOX} label="I’m happy for Audi Foundation to update me with occasional emails on news and events." formProps={formProps} />
                
                {this.state.error && <p className="aui-color-text-red py-2">{this.state.error}</p>}
                <p className="text-center">
                  <Button type="submit" variant="primary" className="red" onClick={() => { this.setState({expandAddressOnError: true}); }}>
                    Confirm {formProps.values.ticketCount && parseInt(formProps.values.ticketCount) > 0 && `payment of $${(parseInt(formProps.values.ticketCount) * ticketPrice).toFixed(2)}`}
                  </Button>
                </p>
                {formProps.isSubmitting && <Loading type="overlay" />}
              </>}
              
              <p className="aui-small mt-3">
                The prize draw will take place on {drawDate} and winners will be contacted directly by the Trustee of the Audi Foundation.
                The name of the winner will also be published on the Audi Foundation website after the draw has taken place.
              </p>
              <p className="aui-small mt-3">
                The Audi Foundation, will collect, hold and use your personal information for the purpose(s) set out above. The Audi Foundation may disclose your personal information to Dealers, related companies or third parties that provide us with services, including to overseas locations (such as Germany). If you consent, The Audi Foundation may also use your personal information to send you communications concerning products, offers, news and events. Our Privacy Policy explains how you can request access to and the correction of your personal information and how you may make a privacy complaint to us. For full details, see our <a className="aui-textlink" href={LINK_PRIVACY} target="_blank" rel="noreferrer nofollow">Privacy Policy</a>.
              </p>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default PrizeDrawForm;
