import React from 'react';
import Helmet from 'react-helmet';
import Loading from '../../components/Loading';
import Hero from '../../components/Hero';
import HeroVideo from '../../components/HeroVideo';
import Button from '../../audi-ui-components/Button';
import PrizeHeadline from './PrizeHeadline';
import PrizeDetails from './PrizeDetails';
import ReceiptForm from './ReceiptForm';
import PrizeDrawForm from './PrizeDrawForm';
import Social from '../../components/Social';
import { DateTime } from 'luxon';
import { gtmPageview } from '../../functions/gtm';
import queryString from 'query-string';

import {
  PATH_DONATE,
  PRIZE_DRAW_ACTIVE,
  PRIZE_DRAW_CLOSED,
  PRIZE_DRAW_BROKEN,
  PRIZE_DRAW_DRAWN,
  PRIZE_DRAW_SOLD_OUT,
  PRIZE_DRAW_NO_RAFFLE,
  FEATURE_TOGGLE
} from '../../constants';

import { connect } from 'react-redux';
import { requestDrawData } from '../../redux/content/prizedraw';
const mapStateToProps = state => {
  return {
    hasLoaded: state.prizedraw ? state.prizedraw.hasLoaded : false,
    pageData: state.prizedraw ? state.prizedraw : {}
  }
}
const mapDispatchToProps = dispatch => {
  return {
    requestDrawData: () => { dispatch(requestDrawData()); }
  }
}

const DEFAULT_STATES = ["NSW", "ACT", "SA", "NT", "QLD", "VIC", "TAS"];

class PrizeDraw extends React.Component {
  
  constructor(props) {
    super(props);
    var q = queryString.parse(props.location.search);
    this.state = {
      submitted: q.thanks,
      receiptUrl: ""
    };
    this.formTopRef = React.createRef();
  }
  
  componentDidMount() {
    gtmPageview(this.props.location.pathname);
    this.props.requestDrawData();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  
  onClickEnter = () => {
    let top = this.formTopRef.current ? this.formTopRef.current.getBoundingClientRect().top : 0;
    let ww = window.innerWidth;
    let offset = 119;
    if (ww < 768) { offset = 45; }
    setTimeout(() => { window.scrollTo({top: top + window.pageYOffset - offset, behaviour: 'smooth'}); }, 500);
  }
  
  render() {
    const { pageData, hasLoaded } = this.props;
    const { submitted, receiptUrl } = this.state;
    const isActive = pageData.status === PRIZE_DRAW_ACTIVE;
    let _drawDate = null;
    if (pageData.drawDate) {
      let _dt = DateTime.fromISO(pageData.drawDate).setLocale("en-AU");
      let _d = _dt.toLocaleString(DateTime.DATE_FULL);
      let _t = _dt.toLocaleString(DateTime.TIME_SIMPLE);
      _drawDate = _d + " at " + _t;
    }
    let ogTitle = pageData.facebookTitle ? pageData.facebookTitle : "Audi Foundation Prize Draw";
    
    return (
      <div className="page prizedraw">
        <Helmet>
          <title>{`${pageData.metaTitle ? pageData.metaTitle : 'Prize Draw'} | Audi Foundation`}</title>
          <meta name="description" content={pageData.metaDescription} />
          <meta property="og:site_name" content="Audi Foundation" />
          <meta property="og:title" content={ogTitle} />
          <meta property="og:description" content={pageData.facebookText} />
          <meta property="og:url" content={"https://audifoundation.com.au/prizedraw"} />
          <meta property="og:image" content={pageData.facebookPhotoUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
        
        {!hasLoaded && <Loading />}
        
        {submitted &&
          <div className="page-inner">
            <div className="content-wrapper">
              <div className="page-intro">
                <p className="aui-headline-2 mb-2"><b>Thank you for supporting the Audi Foundation</b></p>
                <p className="mb-3">
                  Thank you for entering the Audi Foundation prize draw. Your entry helps to support our charity partners make a difference in education, mental health, medical technology and research.
                </p>
                <Social
                  share={{
                    facebook: true,
                    twitter: true,
                    title: ogTitle,
                    twitterText: pageData.twitterText,
                    url: "https://audifoundation.com.au/prizedraw",
                    picture: pageData.facebookPhotoUrl,
                    description: pageData.facebookText
                  }}
                  title="Share now:"
                  className="prizedrawshare mb-5"
                />
                <p className="mb-3">
                  You will shortly receive an email with your ticket(s) and invoice.
                </p>
                <p className="mb-3">
                  The prize draw takes place on {_drawDate}. Good luck and thank you for your support!
                </p>
              </div>
              <div className="cta-section">
                <div className="d-flex flex-column">
                  {receiptUrl && <Button href={receiptUrl} target="_blank" rel="noreferrer" variant="secondary" className="mb-2">Download your ticket(s)</Button>}
                  {!FEATURE_TOGGLE.DONATE.maintenance && <Button to={PATH_DONATE} variant="primary" className="red" onClick={() => {
                    // gtmPush("audiFoundation", "donateNowClick", "TODO", {href: window.location.href});
                  }}>
                    Make a donation to Audi Foundation
                  </Button>}
                </div>
              </div>
            </div>
          </div>
        }
        
        {hasLoaded && !submitted && <>
          
          {isActive && pageData.video && <HeroVideo video={pageData.video} poster={pageData.photoUrl} />}
          {(!isActive || !pageData.video) && <Hero heroImage={pageData.photoUrl} />}

          
          <div className="page-inner">
            <div className="content-wrapper">
              
              <PrizeHeadline {...pageData} />
              
              <div className="cta-section">
                {isActive && <Button variant="primary" className="red" onClick={this.onClickEnter}>Enter now</Button>}
                {!isActive && <Button variant="primary" className="red" to={PATH_DONATE}>Donate now</Button>}
              </div>
              
              {pageData.prizes && <PrizeDetails prizes={pageData.prizes} status={pageData.status} />}
              
              <div ref={this.formTopRef} />
              {isActive &&
                <PrizeDrawForm
                  ticketPrice={pageData.ticketPrice}
                  ticketPerPerson={pageData.ticketPerPerson}
                  ticketOverall={pageData.ticketOverall}
                  drawDate={_drawDate}
                  termsPdf={pageData.termsPdf}
                  drawId={pageData.id}
                  validStates={pageData.eligibleStates && pageData.eligibleStates.length > 0 ? pageData.eligibleStates.split(",").map(s => s.toUpperCase()) : DEFAULT_STATES}
                  onSubmit={(res) => {
                    this.setState({submitted: true, receiptUrl: res.data.receiptUrl});
                    setTimeout(() => {
                      window.scrollTo(0, 0);
                    }, 100);
                  }}
                />
              }
              
            </div>
          
            {FEATURE_TOGGLE.RECEIPTS && <div className="aui-color-gray80">
              <div className="content-wrapper">
                <ReceiptForm />
              </div>
            </div>}
          
            {!submitted && <div className="content-wrapper">
                <hr />
                <div className="aui-small" dangerouslySetInnerHTML={{ __html: pageData.footnotes }} />
            </div>}
            
          </div>
        </>}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrizeDraw);
